import React from "react"
import Layout from "../components/layout"

export default function Home() {
  return (
    <Layout>
        <h1>Halo saya membangun web Blog ini dengan JAMStack Framework Gatsby!</h1>
        <p>
           Apa yang saya pelajari dari pemrograman JAMStack dengan Framework Gatsby sangat menyenangkan. 
           Banyak hal yang saya dapat pelajari disini. Ini bukan bahasa pemrograman biasa seperti halnya HTML.
        </p>
        <p>
          JAMStack adalah bahasa pemrogram luar biasa untuk membangun sebuah frontend website yang ciamik, cantik, cepat dan menyenangkan. 
          Sungguh sangat menggoda saya untuk terus memperdalamnya sampai mahir.
	  <img src="https://source.unsplash.com/random/400x200" alt="" />
        </p>
    </Layout>
  ) 
}
